import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title, Button, Search, Popup, InputCustom, Loading } from '../component';
import { Table, Select, Form } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import allActions from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { UploadIcon } from '../common/asset/icon';
import { Tokens } from '../../constants';
import axios from 'axios';
import { getTokenDecimals } from '../../utils/campaignUltils';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';

const columnsExport = [
    {
        title: 'No.',
        dataIndex: 'key',
    }, {
        title: 'ID',
        dataIndex: 'id',
    }, {
        title: 'Token Name',
        dataIndex: 'name',
    }, {
        title: 'logoURL',
        dataIndex: 'logoURL',
    }, {
        title: 'Symbol',
        dataIndex: 'symbol',
    }, {
        title: 'Token Address',
        dataIndex: 'tokenAddress',
    }, {
        title: 'TOKEN ID',
        dataIndex: 'tokenId',
    }, {
        title: 'Decimals',
        dataIndex: 'decimals',
    }, {
        title: 'Total Supply',
        dataIndex: 'totalSupply',
    }
];

const TokenInform = () => {
    const [isLoad, setIsLoad] = useState(false);
    const [imageData, setImageData] = useState({ url: null, name: null });
    const [isAdd, setIsAdd] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const { Option } = Select;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const inputRef = useRef();
    const tokenInformList = useSelector(state => state.tokenInform);

    useEffect(() => {
        dispatch(allActions.tokenInformAction.getTokenInformList());
    }, []);

    useEffect(() => {
        setFilteredResults(tokenInformList);
    }, [tokenInformList]);

    const onChangeImage = (e) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageData({ url: res.data, name: file.name });
        }).catch(err => {
            console.log(err);
        });
    }

    const onFinish = (values) => {
        values.id = 0;
        values.key = 0;
        values.name = values.name;
        values.logoURL = imageData.url;
        values.tokenId = String(values.tokenId);
        values.decimals = String(values.decimals);
        values.tokenId = values.tokenId ? values.tokenId : null;
        values.decimals = values.decimals ? values.decimals : null;
        values.logoURL = values.logoURL ? values.logoURL : null;
        values.symbol = values.symbol ? values.symbol : null;
        values.tokenAddress = values.tokenAddress ? values.tokenAddress : null;
        values.totalSupply = values.totalSupply ? parseInt(values.totalSupply) : null;

        dispatch(allActions.tokenInformAction.createTokenInform(values, (res) => {
            if (res.callResult) {
                setIsAdd(false);
                Store.addNotification(addNotify('Success', 'success'));
                dispatch(allActions.tokenInformAction.getTokenInformList());
            }
        }));
    };

    const handleDelete = (id) => {
        setDeleteItem(id);
    }

    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            width: '75px',
        }, {
            title: 'TOKEN',
            dataIndex: 'name',
            render: (_, record) => (
                <div className="c-nameTable is-tokenInform">
                    <div className="c-nameTable__logo"><img src={record.logoURL} alt={record.symbol} /></div>
                    <p className="c-nameTable__title">${record.symbol}</p>
                </div>
            ),
            ellipsis: true,
        }, {
            title: 'Token Address',
            dataIndex: 'tokenAddress',
            ellipsis: true,
            render: (text) => <>{text ? text.length > 8 ? `${text.substring(0, 4)}...${text.substring(text.length - 4)}` : text : '--'}</>,
        }, {
            title: 'TOKEN ID',
            dataIndex: 'tokenId',
            ellipsis: true,
            render: (text) => <>{text ? text : '--'}</>,
        }, {
            title: 'Decimals',
            dataIndex: 'decimals',
            ellipsis: true,
            render: (text) => <>{text ? getTokenDecimals(text) : '--'}</>,
        }, {
            title: 'Total Supply',
            dataIndex: 'totalSupply',
            ellipsis: true,
            render: (text) => <>{text ? text : '--'}</>,
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li onClick={() => navigate('/token-inform/' + record.id)}><span>Edit</span></li>
                            <li onClick={() => handleDelete(record.id)}><span>Delete</span></li>
                        </ul>
                    </div>
                </div>
            )
        }
    ];

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("Token Inform")
            .addColumns(columnsExport)
            .addDataSource(tokenInformList, {
                str2Percent: true
            })
            .saveAs("tokenInform.xlsx");
    };

    const onSearch = () => {
        if (searchInput !== '') {
            const filteredData = tokenInformList.filter((item) => {
                return Object.values(item.symbol).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        } else {
            setFilteredResults(tokenInformList)
        }
    }

    return (
        <>
            {isLoad ? <Loading /> : ''}
            <div className="p-tokenInform">
                <div className="p-tokenInform__head">
                    <Title title='Token Inform' subTitle='View statistics of added inform tokens, also you can edit or add new tokens' />
                    <Button title='Add Token Inform' type='primary' iconLeft='BoxAdd' onClick={() => setIsAdd(true)} />
                </div>
                <div className="p-tokenInform__box">
                    <div className="c-table">
                        <div className="c-table__head">
                            <Search
                                placeholder='Search token'
                                onChange={(e) => setSearchInput(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.code === "Enter") {
                                        onSearch();
                                    }
                                }}
                            />
                            <Button title='Export' type='blueBorder' iconRight='Export' onClick={handleExport} />
                        </div>
                        {searchInput.length > 1 ? <Table rowKey='id' columns={columns} dataSource={filteredResults} /> :
                            tokenInformList && tokenInformList.length > 0 && <Table rowKey='id' columns={columns} dataSource={tokenInformList} />
                        }
                    </div>
                </div>
            </div>
            {isAdd ? <Form form={form} name="basic" onFinish={onFinish}>
                <Popup title='Add Token Inform' icon='Add' btnTitle='Add Token Inform' onCancel={() => setIsAdd(false)}>
                    <Form.Item name="id" className="l-displayNone">
                        <InputCustom type="hidden" />
                    </Form.Item>
                    <Form.Item name="key" className="l-displayNone">
                        <InputCustom type="hidden" />
                    </Form.Item>
                    <Form.Item name="logoURL">
                        <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e)} />
                        {imageData.url ? <div onClick={() => inputRef.current.click()} className="c-img"><img src={imageData.url} alt="" />{imageData.name}</div> :
                            <div className="c-uploadIMG">
                                <div onClick={() => inputRef.current.click()} className="c-btn c-btn--flat">
                                    <UploadIcon />Upload Image Token
                                </div>
                            </div>
                        }
                    </Form.Item>
                    <Form.Item name="tokenAddress">
                        <InputCustom placeholder='Token address' />
                    </Form.Item>
                    <Form.Item name="decimals">
                        <Select className="c-selectChange" placeholder='Decimals'>
                            <Option value='picoether'>6</Option>
                            <Option value='1e8'>8</Option>
                            <Option value='nanoether'>9</Option>
                            <Option value='microether'>12</Option>
                            <Option value='ether'>18</Option>
                        </Select>
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item name="name">
                            <InputCustom placeholder='Name' />
                        </Form.Item>
                        <Form.Item name="tokenId">
                            <InputCustom placeholder='Token ID' />
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item name="symbol">
                            <InputCustom placeholder='Symbol' />
                        </Form.Item>
                        <Form.Item name="totalSupply" rules={[{
                            required: false,
                            message: "totalSupply wrong format",
                            pattern: new RegExp(/^\d*(\.\d+)?$/)
                        }]}>
                            <InputCustom placeholder='Total supply' />
                        </Form.Item>
                    </div>
                </Popup>
            </Form> : ''}
            {deleteItem && <Popup
                title='Do you want delete this token inform?'
                subTitle='You can’t restore this file'
                btnTitle='Delete' icon='Delete'
                onCancel={() => setDeleteItem(null)}
                onClick={() => {
                    dispatch(allActions.tokenInformAction.deleteTokenInform(deleteItem));
                    setIsLoad(true);
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 3000);
                }
                }
            />}
        </>
    )
}

export default TokenInform;