
export const Routes = {
    APP_HOME: '/',
    ADMIN_LOGIN: '/login',
    ADMIN_DEFAULT: '/',
};

export const Tokens = {
    ADMIN: 'authToken',
    MEMBER: 'memberToken'
};

export const localStorageActionType = {
    GET_PROFILE: 'GET_PROFILE',
    SET_PROFILE: 'SET_PROFILE',
    CLEAR_PROFILE: 'CLEAR_PROFILE'
};

export const LocalStorageKey = {
    USER: 'user',
    MEMBER: 'member'
};

export const loggedConstant = {
    IS_USER_LOGOUT: 'isUserLogout',
    IS_MEMBER_LOGOUT: 'isMemberLogout'
};

export const Networks = {
    BSC: 'BSC',
    POLY: 'POLY',
    ETH: 'ETH',
    ARB:'ARB',
    ARBTESTNET:'ARBTESTNET',
    HELATESTNET:'HELATESTNET',
    HELA: 'HELA'
};

export const NetworkArray = [
    Networks.BSC, 
    Networks.POLY, 
    Networks.ETH,
    Networks.ARB,
    Networks.HELATESTNET
];

export const Statuses = {
    UPCOMING: 'UPCOMING',
    OPENING: 'OPENING',
    COMPLETED: 'COMPLETED',
    TBA: 'TBA'
};

export const StatusArray = [
    Statuses.UPCOMING, 
    Statuses.OPENING, 
    Statuses.COMPLETED, 
    Statuses.TBA
];

export const whitelistType = {
    STAKING: 'staking',
    PRIORITY: 'priority'
}

export const dataStatus = {
    SUCCESS: "SUCCESS",
    FAIL: "FAIL"
}
export const RoundType = {
    COMMUNITY: 1,
    GUARANTEED: 2,
    PRIVATE: 3,
}
