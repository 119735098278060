import React, {useState, useEffect, useRef} from 'react';
import { Title, Button, Popup, InputCustom, Loading } from '../component';
import { Select, DatePicker, Upload, Form, Input } from 'antd';
import moment from 'moment';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux'; 
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { NetworkArray , Networks} from '../../constants';
import {Tokens} from '../../constants'; 
import axios from 'axios';  

const CampaignEdit = () => {
    const inputImage = useRef();
    const inputLogo = useRef();
    const [form] = Form.useForm();
    const [isDelete, setIsDelete] = useState(false);
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const { TextArea } = Input;
    const { handle } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const campaignData = useSelector(state => state.campaigns);
    const tokenInformData = useSelector(state => state.tokenInform);
    const [campaignPayload, setCampaignPayload] = useState({});
    const [loadingPage, setLoadingPage] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [logoUrl, setLogoUrl] = useState(); 
    const [btnSubmit, setBtnSubmit] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if(handle !== null) {
            dispatch(allActions.campaignAction.getCampaignById(handle));
        }
        dispatch(allActions.tokenInformAction.getTokenInformList());
    },[]);   
     console.log("campaignData",campaignData?.campaignOne);
    useEffect(() => {
        form.setFieldsValue(campaignData);
        form.setFieldsValue({
            id: parseInt(handle),
            network: campaignData?.campaignOne?.network,
            tokenInform: campaignData?.campaignOne?.tokenInform?.id,
            acceptCurrency: campaignData?.campaignOne?.acceptCurrency,
            idoDate: campaignData?.campaignOne?.startIDO ?moment(campaignData?.campaignOne?.startIDO): null,
            joinPoolDate: [campaignData?.campaignOne?.startJoinPool ? moment(campaignData?.campaignOne?.startJoinPool) : null, campaignData?.campaignOne?.endJoinPool ? moment(campaignData?.campaignOne?.endJoinPool): null],
            claimDate: campaignData?.campaignOne?.startClaim ? moment(campaignData?.campaignOne?.startClaim) : null,
            handle:campaignData?.campaignOne?.handle,
            title:campaignData?.campaignOne?.title, 
            priority:campaignData?.campaignOne?.priority,
            stakingDeadline:campaignData?.campaignOne?.stakingDeadline,
            totalRaise:campaignData?.campaignOne?.totalRaise,
            idoRate:campaignData?.campaignOne?.idoRate,
            modelType:campaignData?.campaignOne?.modelType,
            status:campaignData?.campaignOne?.status,
            description:campaignData?.campaignOne?.description,
            announcement:campaignData?.campaignOne?.announcement,
            telegramLink:campaignData?.campaignOne?.telegramLink,
            twitterLink:campaignData?.campaignOne?.twitterLink,
            mediumLink:campaignData?.campaignOne?.mediumLink,
            website:campaignData?.campaignOne?.website,
            facebookLink:campaignData?.campaignOne?.facebookLink,
            discordLink:campaignData?.campaignOne?.discordLink,
            youtubeLink:campaignData?.campaignOne?.youtubeLink,
            fullLink:campaignData?.campaignOne?.fullLink, 
            claimChain: campaignData?.campaignOne?.claimChain
        });
    },[campaignData]);    

   
    const handleDelete = () => {   
        dispatch(allActions.campaignAction.deleteCampaign(handle)); 
        setLoadingPage(true);
        setTimeout(() => {
            navigate('/campaign')
        }, 3000);
    } 

    const changePriority = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            priority: parseInt(e.target.value)
        });
    }

    const changeHandle = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            handle: e.target.value
        });
    }

    const changeNetwork = (value) => {
        setCampaignPayload({
            ...campaignPayload,  
            network: value 
        });
    }

    const changeAcceptCurrency = (value) => {
        setCampaignPayload({
            ...campaignPayload,  
            acceptCurrency: value 
        });
    }

    const changeTokenInform = (value) => {
        setCampaignPayload({
            ...campaignPayload,  
            tokenInformId: parseInt(value)
        });
    }

    const changeStakingDeadline = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            stakingDeadline: parseInt(e.target.value)
        });
    }

    const changeTotalRaise = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            totalRaise: parseInt(e.target.value)
        });
    }

    const changeIDORate = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            idoRate: parseFloat(e.target.value)
        });
    }

    const changeIdoDatePicker = (date, dateString) => { 
        if(date){
            setCampaignPayload({
                ...campaignPayload, 
                idoDate: date ? date?._d.toISOString() : null
            });
        }
    };

    const changeJoinPoolDateRangePicker = (date, dateString) => {  
        if(date){
            setCampaignPayload({
                ...campaignPayload,  
                startJoinPool: date && date[0] ? date[0]?._d.toISOString() : null
            });
    
            setCampaignPayload({
                ...campaignPayload,
                endJoinPool: date && date[1] ? date[1]?._d.toISOString() : null
            });
        } 
    };

    const changeStartClaimDatePicker = (date, dateString) => {
        if(date){
            setCampaignPayload({
                ...campaignPayload, 
                claimDate: date ? date?._d.toISOString() : null
            });
        }
       
    };

    const changeCampaignName = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            title: e.target.value 
        });
    }

    const changeDescription = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            description: e.target.value 
        });
    }

    const changeStatus = (value) => {
        setCampaignPayload({
            ...campaignPayload,  
            status: value 
        });
    }

    const changeAnnouncement = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            announcement: e.target.value 
        });
    }

    const changeTelegramLink = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            telegramLink: e.target.value 
        });
    }

    const changeTwitterLink = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            twitterLink: e.target.value 
        });
    }

    const changeMediumLink = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            mediumLink: e.target.value 
        });
    }

    const changeWebsiteLink = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            website: e.target.value 
        });
    }

    const changeFacebookLink = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            facebookLink: e.target.value 
        });
    }

    const changeDiscordLink = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            discordLink: e.target.value 
        });
    }

    const changeYoutubeLink = (e) => {
        setCampaignPayload({
            ...campaignPayload,  
            youtubeLink: e.target.value
        });
    }

    const onFinish = (values) => { 
      console.log("🚀 ~ file: campaignEdit.js ~ line 245 ~ onFinish ~ values", values)
      
        setLoadingPage(true);
        if (!values.title) {
            Store.addNotification(addNotify('Please enter campaign name !', 'danger'));
            setLoadingPage(false);
            return;
        }

        if (!values.handle) {
            Store.addNotification(addNotify('Please enter campaign handle !', 'danger'));
            setLoadingPage(false);
            return;
        }

        if (!values.tokenInform) {
            Store.addNotification(addNotify('Please select Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }
        
        let _tokenInformValid = tokenInformData.find(x => x.id === values.tokenInform);
        if (!_tokenInformValid) {
            Store.addNotification(addNotify('Please select valid Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }
        
        if (!NetworkArray.includes(values.network)) {
            Store.addNotification(addNotify('Please select valid network !', 'danger'));
            setLoadingPage(false);
            return;
        }
        
        if( values.joinPoolDate){
            if (values?.idoDate?._d > values?.joinPoolDate[0]?._d) {
                Store.addNotification(addNotify('Please select start Join Pool Date >= Ido Date', 'danger'));
                setLoadingPage(false);
                return;
            }
            if (values?.joinPoolDate[1]?._d > values?.claimDate?._d) {
                Store.addNotification(addNotify('Please select Claim Date >= End Join Pool Date', 'danger'));
                setLoadingPage(false);
                return;
            }
        }
       
        let payload = {
            "acceptCurrency": values.acceptCurrency,
            "activated": location.state?.name ==="savedraft" ? btnSubmit ? true : false: true,
            "announcement": values.announcement ? values.announcement : null,
            "bannerURL": imageUrl ? imageUrl :campaignData?.campaignOne?.bannerURL,
            "campaignName": values.title,
            "claimDate": values.claimDate ? values?.claimDate?._d.toISOString() : null,
            "description": values.description ? values.description : null,
            "discordLink": values.discordLink ? values.discordLink : null,
            "facebookLink": values.facebookLink ? values.facebookLink : null,
            "handle": values.handle,
            "idoDate": values.idoDate ? values?.idoDate?._d.toISOString() : null,
            "idoRate": values.idoRate ? values.idoRate : 0,
            "logoUrl": logoUrl ? logoUrl : campaignData?.campaignOne?.logoUrl,
            "modelType":  parseInt(values.modelType) === 1 ? 1: 2,
            "mediumLink": values.mediumLink ? values.mediumLink : null,
            "network": values.network ? values.network : null,
            "priority": values.priority ? values.priority : 0,
            "stakingDeadline": values.stakingDeadline ? values.stakingDeadline : 0,
            "startJoinPool": values.joinPoolDate && values?.joinPoolDate[0] ? values?.joinPoolDate[0]?._d.toISOString() : null,
            "endJoinPool": values.joinPoolDate && values?.joinPoolDate[1] ? values?.joinPoolDate[1]?._d.toISOString() : null,
            "status": values.status ? values.status : "TBA",
            "telegramLink": values.telegramLink ? values.telegramLink : null,
            "tokenInformId": values.tokenInform ? values.tokenInform : 0,
            "totalRaise": values.totalRaise ? values.totalRaise : 0,
            "twitterLink": values.twitterLink ? values.twitterLink : null,
            "website": values.website ? values.website : null,
            "youtubeLink": values.youtubeLink ? values.youtubeLink : null,
            "fullLink": values.fullLink ? values.fullLink : null, 
            "id": values.id,
            "claimChain": values.claimChain ? values.claimChain : null,
        };   

        dispatch(allActions.campaignAction.updateCampaign(payload, (response) => { 
            if(response.status === 200){ 
                if(location.state?.name ==="savedraft"){
                    navigate('/savedraft');
                }
                else{
                    navigate('/');
                }
                
                setLoadingPage(false);
            }
            else{ 
                Store.addNotification(addNotify(response.title, 'danger')); 
                setLoadingPage(false);
            }  
        }));   
        setLoadingPage(false);
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }; 

    const onChangeImage = (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageUrl(res.data);
            setCampaignPayload({
            ...campaignPayload,  
            logoURL: res.data
            });
        }).catch(err => {
            console.log(err);
        }); 
    }
 
    const onChangeLogo = (e) => {  
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setLogoUrl(res.data);
            setCampaignPayload({
            ...campaignPayload,  
            logoURL: res.data
            });
        }).catch(err => {
            console.log(err);
        });
    } 
    const changeModal = (value) => {
        setCampaignPayload({
            ...campaignPayload,  
            network: value 
        });
    }
    return(
        <>
        <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item name="id" >
                <InputCustom type="hidden" />
            </Form.Item>
            <div className="p-campaignEdit">
                <div className="p-campaignEdit__left">
                    <Title title='Campaign’s Information' subTitle='Enter the required information below to add project. You can change it before the project ends.' />
                    <div className="p-campaignEdit__row">
                        <div className="c-select">
                            <Form.Item name="priority">
                                <InputCustom title='Priority' value='1' onChange={changePriority} type="number" />
                            </Form.Item>
                        </div>
                        <div className="c-select">
                            <Form.Item 
                                name="handle" 
                                rules={[{ required: true, message: 'Campaign handle is required!' }]}
                            >
                                <InputCustom title='Handle' required={true} onChange={changeHandle} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="p-campaignEdit__row">
                        <div className="c-select">
                            <p className="c-select__title">Network <span style={{color: 'red'}}>*</span></p>
                            <Form.Item 
                                name="network"  
                            >
                                <Select className="c-selectChange" placeholder="Select a option network" onChange={changeNetwork}>
                                    <Option value={Networks.BSC}><img src="../images/icon-binance.svg" alt="Binance Smart Chain" />Binance Smart Chain</Option>
                                    <Option value={Networks.POLY}><img src="../images/icon-polygon.svg" alt="Polygon" />Polygon</Option>
                                    <Option value={Networks.ETH}><img src="../images/icon-ethereum.svg" alt="Ethereum" />Ethereum</Option>
                                    {process.env.REACT_APP_MODE ==="development" &&
                                    <>
                                        <Option value={Networks.ARBTESTNET}><img src="../images/icon-arb.svg" alt="Arbitrum" />ArbitrumTest</Option>
                                        <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>
                                        <Option value={Networks.HELATESTNET}><img src="../images/hela-icon.svg" alt="Hela Testnet" />Hela Testnet</Option>
                                        <Option value={Networks.HELA}><img src="../images/hela-icon.svg" alt="Hela" />Hela</Option>
                                    </>
                                    }
                                    {process.env.REACT_APP_MODE ==="production" && <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>}
                                    {process.env.REACT_APP_MODE ==="production" && <Option value={Networks.HELA}><img src="../images/hela-icon.svg" alt="Hela" />Hela</Option>}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="c-select">
                            <p className="c-select__title">Claim network <span style={{color: 'red'}}>*</span></p>
                            <Form.Item 
                                name="claimChain"  
                            >
                                <Select className="c-selectChange" placeholder="Select a option network" onChange={changeNetwork}>
                                    <Option value={Networks.BSC}><img src="../images/icon-binance.svg" alt="Binance Smart Chain" />Binance Smart Chain</Option>
                                    <Option value={Networks.POLY}><img src="../images/icon-polygon.svg" alt="Polygon" />Polygon</Option>
                                    <Option value={Networks.ETH}><img src="../images/icon-ethereum.svg" alt="Ethereum" />Ethereum</Option>
                                    {process.env.REACT_APP_MODE ==="development" && 
                                        <>
                                            <Option value={Networks.ARBTESTNET}><img src="../images/icon-arb.svg" alt="Arbitrum" />ArbitrumTest</Option>
                                            <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>
                                            <Option value={Networks.HELATESTNET}><img src="../images/hela-icon.svg" alt="Hela Testnet" />Hela Testnet</Option>
                                            <Option value={Networks.HELA}><img src="../images/hela-icon.svg" alt="Hela" />Hela</Option>
                                        </> 
                                        }
                                    {process.env.REACT_APP_MODE ==="production" && <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>}
                                    {process.env.REACT_APP_MODE ==="production" && <Option value={Networks.HELA}><img src="../images/hela-icon.svg" alt="Hela" />Hela</Option>}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="p-campaignEdit__row">
                        <div className="c-select">
                            <p className="c-select__title">Accept Currency <span style={{color: 'red'}}>*</span></p>
                            <Form.Item 
                                name="acceptCurrency"  
                            >
                                <Select className="c-selectChange" onChange={changeAcceptCurrency}>
                                    <Option value="BUSD"><img src="../images/icon-busd.svg" alt="BUSD" />BUSD</Option>
                                    <Option value="USDC"><img src="../images/icon-usdc.svg" alt="USDC" />USDC</Option>
                                    <Option value="USDT"><img src="../images/icon-usdt.svg" alt="USDT" />USDT</Option>
                                    <Option value="HLUSD"><img src="../images/helalogo.svg" alt="HLUSD" />HLUSD</Option>
                                    <Option value="HUSD"><img src="../images/helalogo.svg" alt="HUSD" />HUSD</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    
                        <div className="c-select">
                            <p className="c-select__title">Token Inform <span style={{color: 'red'}}>*</span></p>
                            <Form.Item 
                                name="tokenInform" 
                            > 
                            <Select className="c-selectChange" onChange={changeTokenInform}>
                                {tokenInformData && tokenInformData.map((token) => (
                                    <Option key={token.id} value={token.id}>
                                        <img src={token.logoURL} alt={token.symbol + " - " + token.name} />
                                        {token.symbol} - {token.name}
                                    </Option>)
                                )}
                            </Select> 
                            </Form.Item>
                        </div>
                    </div>
                    <div className="p-campaignEdit__row">
                        <div className="c-select">
                            <Form.Item name="stakingDeadline"
                                rules={[{ 
                                    required: false, 
                                    message: "Staking Deadline wrong format",
                                    pattern: new RegExp(/^\d*(\.\d+)?$/)
                                }]}>
                                <InputCustom title='Staking Deadline (hours)' note='<p>Period time from the stake deadline to the start of pool join</p>' onChange={changeStakingDeadline} />
                            </Form.Item>
                        </div>
                    
                        <div className="c-select">
                            <Form.Item name="totalRaise"
                                rules={[{ 
                                    required: false, 
                                    message: "Total Raise wrong format",
                                    pattern: new RegExp(/^\d*(\.\d+)?$/)
                                }]}>
                                <InputCustom title='Total Raise (BUSD)' onChange={changeTotalRaise} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="p-campaignEdit__row">
                        <div className="c-select">
                            <Form.Item name="idoRate"
                             rules={[{ 
                                required: false, 
                                message: "Total Raise wrong format",
                                pattern: new RegExp(/^\d*(\.\d+)?$/)
                            }]}>
                                <InputCustom title='IDO Rate (BUSD)' onChange={changeIDORate} />
                            </Form.Item>
                        </div>
                        <div className="c-select">
                            <p className="c-select__title">Model type<span style={{color: 'red'}}>*</span></p>
                            <Form.Item 
                                name="modelType"  
                            >
                                <Select className="c-selectChange" placeholder="Select a option modal" onChange={changeModal}>
                                    <Option value="1">Model 1</Option>
                                    <Option value="2">Model 2</Option> 
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="p-campaignEdit__timeTable">
                        <h3 className="c-ttl">Campaign’s Timetable</h3>
                        <div className="p-campaignEdit__box">
                            <div className="c-date">
                                <p className="c-date__title">IDO Date (GMT+7)</p>
                                <Form.Item name ="idoDate"> 
                                    <DatePicker showTime format='HH:mm:ss YYYY-MM-DD' onChange={changeIdoDatePicker} />
                                </Form.Item> 
                            </div>
                            <div className="c-date">
                                <p className="c-date__title">Join Pool Date (GMT+7)</p>
                                <Form.Item name ="joinPoolDate"> 
                                    <RangePicker  
                                     showTime
                                     format='HH:mm:ss YYYY-MM-DD'
                                     onChange={changeJoinPoolDateRangePicker}/>
                                </Form.Item>
                            </div>
                            <div className="c-date">
                                <p className="c-date__title">Claim Date (GMT+7)</p> 
                                <Form.Item name ="claimDate"> 
                                    <DatePicker  
                                    showTime
                                    format='HH:mm:ss YYYY-MM-DD'
                                    onChange={changeStartClaimDatePicker}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="p-campaignEdit__right">
                    <div className="c-upload">
                        <h3 className="c-upload__title">Banner/ Video</h3>   
                        <div className='c-upload__box'>  
                            {imageUrl ? 
                                <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                                    <img src={imageUrl} alt="" />
                                </div>:
                                <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                                    <img src={campaignData?.campaignOne?.bannerURL} alt="" />
                                </div> }  
                            <input ref={inputImage} onChange={onChangeImage} type="file" />
                        </div>
                       
                    </div>
                    <div className="p-campaignEdit__row">
                        <div className="c-select">
                        <Form.Item 
                            name="title" 
                            rules={[{ required: true, message:"Campaign Name is required" }]}
                        >
                            <InputCustom title='Campaign Name' required={true} value='Fado Go' onChange={changeCampaignName} />
                        </Form.Item>
                        </div>
                        <div className="c-select">
                            <h3 className="c-upload__title">Logo</h3>  
                            <div className="c-upload__box"> 
                                {logoUrl ? 
                                    <div onClick={() => inputLogo.current.click()} className="c-upload__edit is-logo">
                                        <img src={logoUrl} alt="" />
                                    </div>:
                                    <div onClick={() => inputLogo.current.click()} className="c-upload__edit is-logo">
                                        <img src={campaignData?.campaignOne?.logoUrl} alt="" />
                                    </div> } 
                                <input ref={inputLogo} onChange={onChangeLogo} type="file" />  
                            </div>
                        </div>
                    </div>
                    <div className="p-campaignEdit__row is-wFull"> 
                        <div className="c-select">
                            <div className="c-textarea">
                                <p className="c-textarea__title">Description</p>
                                <Form.Item name="description" className="c-textarea__custom">
                                    <TextArea onChange={changeDescription} />
                                </Form.Item>
                            </div> 
                        </div>
                    </div>
                    <div className="p-campaignEdit__row is-wFull">
                        <div className="c-select">
                            <p className="c-select__title">Status</p>
                            <Form.Item name="status">
                                <Select className="c-selectChange" onChange={changeStatus}>
                                    <Option value="UPCOMING">Upcoming</Option>
                                    <Option value="OPENING">Opening</Option>
                                    <Option value="COMPLETED">Completed</Option>
                                    <Option value="TBA">TBA</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        
                    </div>
                    <div className="p-campaignEdit__row is-wFull">
                        <div className="c-select">
                        <Form.Item name="announcement">
                            <InputCustom title='Announcement' placeholder='Enter announcement' onChange={changeAnnouncement} />
                        </Form.Item>
                        </div>
                    </div>
                    <div className="p-campaignEdit__row is-wFull">
                        <h3 className="c-ttl is-font16">Channel Media</h3>
                        <ul className="c-media">
                            <li>
                                <p>Channel name</p>
                                <p>URL <span>(Required)</span></p>
                            </li>
                            <li>
                                <h3><img src="../images/icon-telegram.svg" alt="" />Telegram</h3>
                                <div className='inputCustom'>
                                <Form.Item name="telegramLink">
                                    <InputCustom placeholder='Enter link' onChange={changeTelegramLink} />
                                </Form.Item>
                                </div>
                            </li>
                            <li>
                                <h3><img src="../images/icon-twitter.svg" alt="" />Twitter</h3>
                                <div className='inputCustom'>
                                <Form.Item name="twitterLink">
                                    <InputCustom placeholder='Enter link' onChange={changeTwitterLink} />
                                </Form.Item>
                                </div> 
                            </li>
                            <li>
                                <h3><img src="../images/icon-medium.svg" alt="" />Medium</h3>
                                <div className='inputCustom'>
                                <Form.Item name="mediumLink">
                                    <InputCustom placeholder='Enter link' onChange={changeMediumLink} />
                                </Form.Item>
                                </div> 
                            </li>
                            <li>
                                <h3><img src="../images/icon-website.svg" alt="" />Website</h3>
                                <div className='inputCustom'>
                                <Form.Item name="website">
                                    <InputCustom placeholder='Enter link' onChange={changeWebsiteLink} />
                                </Form.Item>
                                </div>  
                            </li>
                            <li>
                                <h3><img src="../images/icon-facebook.svg" alt="" />Facebook</h3>
                                <div className='inputCustom'>
                                    <Form.Item name="facebookLink">
                                        <InputCustom placeholder='Enter link' onChange={changeFacebookLink} />
                                    </Form.Item>
                                </div>
                                
                            </li>
                            <li>
                                <h3><img src="../images/icon-discord.svg" alt="" />Discord</h3> 
                                <div className='inputCustom'>
                                    <Form.Item name="discordLink">
                                        <InputCustom placeholder='Enter link' onChange={changeDiscordLink}/>
                                    </Form.Item> 
                                </div> 
                            </li>
                            <li>
                                <h3><img src="../images/icon-youtube.svg" alt="" />Youtube</h3>
                                <div className='inputCustom'>
                                <Form.Item name="youtubeLink">
                                    <InputCustom placeholder='Enter link' onChange={changeYoutubeLink}/>
                                </Form.Item>
                                </div>
                            </li>
                            <li>
                                <h3><img src="../images/icon-website.svg" alt="" />Full Link</h3>
                                <div className='inputCustom'>
                                    <Form.Item name="fullLink">
                                        <InputCustom placeholder='Enter link'/>
                                    </Form.Item>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="p-campaignEdit__btn">
                    <div className="p-campaignEdit__left">
                        <Button title='Cancel' type='grey' /> 
                    </div>
                    <div className="p-campaignEdit__right">
                        <div className="c-btn c-btn--flat" type='flat' onClick={() => setIsDelete(true)}>Delete</div> 
                            <Button title='Edit' type='blue' />
                        {location.state?.name === "campaign" ? null 
                        : <div className="p-campaignEdit__right">  
                            <Button title='Submit' type='blue' onClick={()=>setBtnSubmit(true)}/>
                        </div>} 
                    </div>
                    
                </div>
                {isDelete ? <Popup onClick={() => handleDelete()} title='Do you want delete this campaign?' subTitle='You can’t restore this file' btnTitle='Delete' icon='Delete' onCancel={() => setIsDelete(false)} /> : ''}
            </div>
        </Form>
        {loadingPage ? <Loading /> : ''}  
        </>
    )
}



export default CampaignEdit;
